import './App.css';
import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';

import { Amplify } from '@aws-amplify/core';
import { Authenticator } from '@aws-amplify/ui-react';

import { 
  ampli,
  getTheme,
  ErrorBox, 
  TopAppBar, 
  AUTHN_CONFIG, 
  RequireAuthN,
  Login, 
  Logout,
  ChangePassword, 
  UserProfile 
} from 'revelata-common-ui';

import Home from './components/Home';

Amplify.configure(AUTHN_CONFIG);

export function App() {
  document.title = 'Revelata_ | beta'
  const Theme = createTheme(getTheme('dark'));

  useEffect(() => {
    return () => { ampli.flush(); };  // This is called when the component is unmounted.
  }, []);

  return (
    <>
    <ThemeProvider theme={Theme}>
      <Authenticator.Provider>
        <Router>
          <TopAppBar defaultTabValue={0}/>
          <Box sx={{ height: "calc(100vh - 85px)", minHeight: "calc(100vh - 85px)"}}> {/* 85px is the height of TopAppBar */}
            <ErrorBoundary FallbackComponent={ErrorBox}>
            <Routes>
              <Route path="/" element={<Home/>}/>
              <Route path="/login" element={<Login tab="signIn"/>}/>
              <Route path="/logout" element={<Logout/>}/>
              <Route path="/signup" element={<Login tab="signUp"/>}/>
              <Route path="/changepassword" element={
                <RequireAuthN><ChangePassword/></RequireAuthN>
              }/>
              <Route path="/profile" element={
                <RequireAuthN><UserProfile/></RequireAuthN>
              }/>
              <Route path="*" element={<Home/>}/>
            </Routes>
            </ErrorBoundary>
          </Box>
        </Router>              
      </Authenticator.Provider>
      </ThemeProvider>
    </>    
  );
}

export default App;