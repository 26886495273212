import React from 'react';
import ReactDOM from 'react-dom/client';
import { CookiesProvider } from 'react-cookie';
import './index.css';
import App from './App';

import CssBaseline from '@mui/material/CssBaseline';

import {
  experimental_extendTheme as extendTheme,
  Experimental_CssVarsProvider as CssVarsProvider,  
} from '@mui/material/styles';


import { ampli } from 'revelata-common-ui'; 
ampli.load(
  { 
    client: { 
      apiKey: process.env.REACT_APP_AMPLI_API_KEY, 
      configuration: {
        defaultTracking: true,
        serverUrl: process.env.REACT_APP_AMPLITUDE_PROXY
      }
    } 
  }
);

const theme = extendTheme({
  typography: {
    fontFamily: 'figtree',
    fontWeightRegular: 400
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <CssVarsProvider theme={theme} defaultMode="dark" modeStorageKey="dark-mode-default">
      <CssBaseline enableColorScheme/>
      <CookiesProvider defaultSetOptions={{ path: '/' }}>
        <App />
      </CookiesProvider>
    </CssVarsProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
